import React, { useState, useEffect } from 'react';
import mapboxgl from 'mapbox-gl';
import './../css/AddressAutocomplete.css'; // Import CSS for styling

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

const AddressAutocomplete = ( { onSelectAddress, onCityChange, onStateChange, onPostcodeChange } ) => {
  const [address, setAddress] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!event.target.closest('.address-autocomplete')) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const fetchSuggestions = async (value) => {
    try {
      const response = await fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(value)}.json?country=au&access_token=${mapboxgl.accessToken}`);
      const data = await response.json();
      setSuggestions(data.features);
    } catch (error) {
      console.error('Error fetching address data:', error);
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setAddress(value);
    if (value.length > 2) {
      fetchSuggestions(value);
    } else {
      setSuggestions([]);
    }
    setShowSuggestions(true);
  };

  const handleSuggestionClick = (suggestion) => {
    // Extracting street name and street number
    const streetName = suggestion.place_name.split(",")[0];

    // Extracting city, state, and postcode from the suggestion
    const context = suggestion.context;
    const city = context.find(c => c.id.startsWith('place')).text;
    const state = context.find(s => s.id.startsWith('region')).short_code.split("-")[1];
    const postcode = context.find(p => p.id.startsWith('postcode')).text;

    // Update the address input with street name and number only
    setAddress(streetName);

    // Call the callbacks to update city, state, and postcode in the parent component
    onCityChange(city);
    onStateChange(state);
    onPostcodeChange(postcode);

    setSuggestions([]);
    onSelectAddress(suggestion);
    setShowSuggestions(false);
  };

  return (
    <div className="address-autocomplete">
      <input
        type="text"
        value={address}
        onChange={handleInputChange}
        className="form-control"
        autoComplete="off"
      />
      {showSuggestions && suggestions.length > 0 && (
        <ul className="suggestions-dropdown">
          {suggestions.map((suggestion) => (
            <li key={suggestion.id} onClick={() => handleSuggestionClick(suggestion)}>
              {suggestion.place_name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default AddressAutocomplete;
