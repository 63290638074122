import React, { useContext } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import VerificationContext from '../context/VerificationContext';
import Sidebar from '../components/Sidebar';
import DeliveryForm from '../components/Form_Three';
import { Container } from 'react-bootstrap';
import FormProgress from '../components/FormProgress';

const SecondPage = () => {
    const { isVerified } = useContext(VerificationContext);

    return (
        <>
            <Row style={{ height: '100vh' }}>
                <Col xs={12} md={8}>
                    <Row>
                        <Col xs={12} md={1}></Col>
                        <Col xs={12} md={9}>
                            <Container>
                                <img src="/logomark.svg" style={{height:'100px', width:'100px'}} />
                                <h5>Bright-Duggan Key Device Request Form</h5>
                                <FormProgress />
                            </Container>
                            <DeliveryForm />
                        </Col>
                        <Col xs={12} md={1}></Col>
                    </Row>
                </Col>
                <Col xs={12} md={4} style={{ backgroundColor: '#D1F7FF'}}>
                    <Sidebar />
                </Col>
            </Row>
        </>
    )
}

export default SecondPage;