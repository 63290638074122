import axios from 'axios';

const instance = axios.create({
    baseURL: process.env.REACT_APP_STRATA_API_ADDRESS || 'https://nsw-strata-details.p.rapidapi.com', 
    // You can add your headers here,
    headers: {
        'X-RapidAPI-Key': process.env.REACT_APP_STRATA_API_KEY,
        //host is api address without https://
        'X-RapidAPI-Host':  process.env.REACT_APP_STRATA_API_ADDRESS,
    }
});

export default instance;