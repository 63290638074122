import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from '../api/axios';
import { AuthContext } from '../context/AuthContext'; // Adjust the path as needed
import VerificationContext from '../context/VerificationContext';
const CallbackPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [error, setError] = useState(null);
    const { updateAuthData } = useContext(AuthContext);
    const { setIsVerified } = useContext(VerificationContext);


    useEffect(() => {

        console.log('rendered callback page')

        const getError = () => {
            const queryParams = new URLSearchParams(location.search);
            const errorCode = queryParams.get('error');
            const errorDescription = queryParams.get('error_description');
            return { errorCode, errorDescription };
        };

        const retrieveTokens = async () => {
            try {
                const response = await axios.get(`/retrieve-tokens${location.search}`, {withCredentials: true});
                    
                const { claims, token, xFapiInteractionId } = response.data;
                updateAuthData({ claims, token, xFapiInteractionId });
                setIsVerified(true)
                navigate('/');
            } catch (error) {
                console.error('Error retrieving tokens:', error);
                setError(error.toString());
            }
        };

        const { errorCode, errorDescription } = getError();
        console.log(errorCode, errorDescription);
        if (errorCode) {
            setError(`${errorCode}: ${decodeURIComponent(errorDescription)}`);
            if(errorDescription === "End-User aborted interaction"){
                navigate('/', { state: { errorMessage: 'Verification was not completed' }} )
            } else {
                navigate('/', { state: { errorMessage: 'Something went wrong. Please try again' }} )
            }

            return;
        }
        retrieveTokens();
    }, []);

    if (error) {
        return <div>Error: {error}</div>;
    }

    return <div>Redirecting...</div>;
};

export default CallbackPage;