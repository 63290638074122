// ProgressContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';

const ProgressContext = createContext();

export const useProgressContext = () => useContext(ProgressContext);

export const ProgressProvider = ({ children }) => {
  const [progress, setProgress] = useState(1);
  const location = useLocation();
  
  const goToNextStep = () => setProgress((prev) => prev + 1);
  const goToPreviousStep = () => setProgress((prev) => Math.max(1, prev - 1));

  // console.log(location.pathname)
  useEffect(() => {
    switch(location.pathname){
      case '/':
        setProgress(1);
        break;
      case '/key-selection':
        setProgress(2);
        break;
      case '/delivery':
        setProgress(3);
        break;
      default:
        setProgress(1);
        break;
    }
  }, [location.pathname]);

  return (
    <ProgressContext.Provider value={{ progress, goToNextStep, goToPreviousStep }}>
      {children}
    </ProgressContext.Provider>
  );
};