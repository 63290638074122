import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_MAIN_API_ADDRESS,
  // baseURL: 'https://localhost:8080/',
  // You can add your headers here
  headers: {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + process.env.REACT_APP_MAIN_API_ADDRESS,
  }
});

export default instance;