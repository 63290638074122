import React from 'react';
import '../css/Sidebar.css'
import Container from 'react-bootstrap/Container';

const Sidebar =  ({ isExpanded }) => {
    return (
        <>
            <Container className='sidebar-container p-3 pt-5' id="sidebar">
                <h2>Steps to request your key devices</h2>
                <ol style={{ marginBottom: '30px' }}>
                    <li>Verify your identity safely with ConnectID</li>
                    <li>Once verified, enter your Strata Plan Number</li>
                    <li>Check your details are correct, and continue</li>
                    <li>Select the key devices you require</li>
                    <li>Fill out delivery details</li>
                    <li>Submit your request. Once confirmed, an invoice will be emailed to you</li>
                </ol>

                <h2>What is Connect ID?</h2>
                <p>ConnectID is an Australian-owned digital identity
            solution which allows you to securely prove who you
            are. ConnectID does not see or store your personal
            data. Visit <a target="_blank"  href='https://www.connectid.com.au'>connectid.com.au</a> to find out more.</p>

                <p>All information is collected in accordance with our <a target="_blank" href='https://www.keyguardian.com.au/privacy-policy'> Privacy Statement</a>.</p>
                {isExpanded && <div className="sidebar-spacer" style={{ height: '50vh' }}></div>}
            </Container>
                    </>
    )

}

export default Sidebar;