import React, { useState, useContext, useEffect} from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import DeviceSelector from './DeviceSelector';
import { Button } from 'react-bootstrap';
import { VerifiedWith } from './VerifiedWith';
import axios from './../api/axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';


const KeySelectionForm = () => {

    // State - eventually form state
    const [keyReason, setKeyReason] = useState('');
    const [products, setProducts] = useState([{keyType: '', quantity: 1, textInput: '', id: uuidv4()}]);
    const navigate = useNavigate();
    const location = useLocation();


    const handleChange = (event) => {
        setKeyReason(event.target.value);
    };

    const addProductSelector = () => {
        setProducts([...products, { keyType: '', quantity: 1, textInput: '', id: uuidv4() }]);
    };

    const updateProduct = (productId, updatedProduct) => {
        setProducts(prevProducts => 
            prevProducts.map(product => 
                product.id === productId ? updatedProduct : product
            )
        );
    };

    const removeProduct = (productId) => {
        setProducts(prevProducts => prevProducts.filter(product => product.id !== productId));
    };

    
    const handleContinue = async () => {
        const userId = location.state?.user.id || localStorage.getItem('userId');
        const buildingId = location.state?.building.id || localStorage.getItem('buildingId');
        const orderDetails = {
            userId,
            buildingId,
            reason: keyReason,
            keys: products.map(product => ({
                type: product.keyType,
                qty: product.quantity,
                device: product.textInput
            }))
        };

        try {
            const response = await axios.post('/key-details', orderDetails, { withCredentials: true });
            if (response.status === 201) {
                const order = response.data.order;
                localStorage.setItem('order', JSON.stringify(order)); // Store order as JSON string
                navigate('/delivery', { state: { order, user: location.state.user, building: location.state.building } });
            } else {
                // Handle error
                console.error('Error submitting order:', response);
            }
        } catch (error) {
            console.error('Order submission error:', error);
        }
    };

    if (!location.state?.user) {
        return (
            <div className="main-form container">
                <h2>Oops! Something went wrong</h2>
                <p>We couldn't find your user information. Please try again or contact support.</p>
            </div>
        );
    }

    return (
        <div className='main-form container'>
            {/* <p>Current Step: {progress}</p>
            <button onClick={goToPreviousStep}>Previous</button>
            <button onClick={goToNextStep}>Next</button> */}

            <Container>
                <h2>Now, what keys do you need?</h2>
                {/* Show the user and their address - Summary */}
                <Row className='my-4'>
                    <VerifiedWith fontSize="0.7em" padding="5px 10px" margin="0 0 10px 10px" />
                    <p className='mb-0'>{location.state.user.firstName + ' ' + location.state.user.lastName}</p>
                    <p className='mb-0'>{location.state.user.email}</p>
                    <p className='mb-2'>{location.state.user.mobile}</p>

                    <hr />
                    <p>{`${location.state.building.address}, ${location.state.building.city}, ${location.state.building.state} ${location.state.building.postcode}`}</p>
                </Row>

                <Row className='my-4'>
                    <label htmlFor="newKeyReason"><b>Reason for new device(s)?</b></label>
                    <Form.Group>
                        <Form.Check 
                            type="radio"
                            name="keyReason"
                            value="additional"
                            label="These are additional to my existing key devices"
                            checked={keyReason === 'additional'}
                            onChange={handleChange}
                            id="additionalKey"
                        />
                        <Form.Check 
                            type="radio"
                            name="keyReason"
                            value="lost"
                            label="I've lost my key devices and these are replacements"
                            checked={keyReason === 'lost'}
                            onChange={handleChange}
                            id="lostKey"
                        />
                        <Form.Check 
                            type="radio"
                            name="keyReason"
                            value="new"
                            label="These are new (to me)"
                            checked={keyReason === 'new'}
                            onChange={handleChange}
                            id="newKey"
                        />
                    </Form.Group>
                </Row>

                <Row className='my-4'>
                    <label htmlFor="newKeyReason"><b>Select your keys/device</b></label>
                    {products.map((product) => (
                        <DeviceSelector
                            key={product.id}
                            product={product}
                            updateProduct={updateProduct}
                            removeProduct={removeProduct}
                        />
                    ))}
                </Row>
                <Row>
                    <Col xs={12} sm={12} className='mt-2 px-1'>
                        <Button onClick={ addProductSelector } style={{ maxWidth: 'max-content', border: '1px solid #6d6d6d', background: 'transparent', color: '#6d6d6d'}}>
                            <svg className='mx-2 ml-0' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M12 2.25C6.624 2.25 2.25 6.624 2.25 12C2.25 17.376 6.624 21.75 12 21.75C17.376 21.75 21.75 17.376 21.75 12C21.75 6.624 17.376 2.25 12 2.25ZM12 3.75C16.5653 3.75 20.25 7.43475 20.25 12C20.25 16.5653 16.5653 20.25 12 20.25C7.43475 20.25 3.75 16.5653 3.75 12C3.75 7.43475 7.43475 3.75 12 3.75ZM11.25 7.5V11.25H7.5V12.75H11.25V16.5H12.75V12.75H16.5V11.25H12.75V7.5H11.25Z" fill="#6D6D6D"/>
                            </svg>
                            <span>Add another device</span></Button>
                    </Col>
                </Row>

                <Row className='my-4'>
                    <Col xs={12} sm={8} className='px-1'>
                        <Button onClick={ handleContinue } variant='dark' size='lg' style={{ padding: '5px 30px', marginTop: '2em'}}>Continue</Button>
                    </Col>
                </Row>
            </Container>

        </div>
        )
}

export default KeySelectionForm;
