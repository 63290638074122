import React, { useState, useCallback, useEffect } from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { VerifiedWith } from './VerifiedWith';
// import { AddressAutofill, AddressMinimap, useConfirmAddress, config } from '@mapbox/search-js-react';
import axios from './../api/axios';
import mapboxgl from 'mapbox-gl';
import AddressAutocomplete from './AddressAutocomplete';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

const DeliveryForm = () => {

    // State - eventually form state
    const [keyReason, setKeyReason] = useState('additional');
    const [products, setProducts] = useState([{keyType: '', quantity: 1, textInput: ''}]);
    const [touched, setTouched] = useState({});
    const [selectedAddress, setSelectedAddress] = useState({});
    const navigate = useNavigate();
    const location = useLocation();

    const [isRequestingPersonTakingDelivery, setIsRequestingPersonTakingDelivery] = useState(false);
    const [receiverDetails, setReceiverDetails] = useState({
        receiverFirstName: '',
        receiverLastName: '',
        receiverMobile: '',
        receiverEmail: ''
    });


    const [formData, setFormData] = useState({
        address: '',
        postcode: '',
        city: '',
        state: '',
        unitNumber: ''
    });

    // Handles mapbox address selection
    const handleSelectAddress = (address) => {
        setSelectedAddress({
          address: address.place_name.split(",")[0],
          postcode: address.context[0].text,
          city: address.context[1].text,
          state: address.context[3].short_code.split("-")[1],
        });

        setFormData({
            address: address.place_name.split(",")[0],
            postcode: address.context[0].text,
            city: address.context[1].text,
            state: address.context[3].short_code.split("-")[1]
        })
    };

    const isValid = (field) => {
        // Replace the following with your validation logic
        return formData[field].length > 0;
    };

    const shouldMarkError = (field) => {
        return touched[field] && !isValid(field);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleChangeReceiverDetails = (event) => {
        const { name, value } = event.target;
        setReceiverDetails({ ...receiverDetails, [name]: value });
    };

    
    const handleContinue = async () => {
        
        const order = location.state?.order || localStorage.getItem('order');
        
        if (!order) {
            // Handle missing orderId (e.g., redirect to an earlier step)
            navigate('/flow-break');
            return;
        }

        const orderId = order.id

        const deliveryDetails = {
            orderId,
            ...formData,
            // Add receiver details if the requesting person is not taking delivery
            ...(isRequestingPersonTakingDelivery ? {} : receiverDetails)
        };

        try {
            const response = await axios.post(`/delivery-details?receiver=${isRequestingPersonTakingDelivery}`, deliveryDetails, { withCredentials: true });
            if (response.status === 201) {
                console.log('Delivery details saved:', response.data);

                navigate('/complete'); // Navigate to the next step
            } else {
                throw new Error('Delivery details submission failed');
            }
        } catch (error) {
            console.error('Delivery details submission error:', error);
        }
    };

    const handleRetrieve = useCallback(
        // (res) => {
        // const feature = res.features[0];
        // setFeature(feature);
        // setShowMinimap(true);
        // setShowFormExpanded(true);
        // },
        // [setFeature, setShowMinimap]
    );

    const handleCityChange = (city) => {
        setFormData({ ...formData, city });
    };
    
    const handleStateChange = (state) => {
        setFormData({ ...formData, state });
    };
    
    const handlePostcodeChange = (postcode) => {
        setFormData({ ...formData, postcode });
    };


    return (
        <div className='main-form container'>
            {/* <p>Current Step: {progress}</p>
            <button onClick={goToPreviousStep}>Previous</button>
            <button onClick={goToNextStep}>Next</button> */}

            <Container>
                <h2>And last, your delivery instructions</h2>
                {/* Show the user and their address - Summary */}
                <Row className='my-4'>
                    <VerifiedWith fontSize="0.7em" padding="5px 10px" margin="0 0 10px 10px" />
                    <p className='mb-0'>{location.state.user.firstName + ' ' + location.state.user.lastName}</p>
                    <p className='mb-0'>{location.state.user.email}</p>
                    <p className='mb-2'>{location.state.user.mobile}</p>

                    <hr />
                    <p>{`${location.state.building.address}, ${location.state.building.city}, ${location.state.building.state} ${location.state.building.postcode}`}</p>
                </Row>

                <form>



                <Row className="mb-3">
                    <Col xs={12}>
                        <Form.Check 
                            type="checkbox" 
                            label="Is the Requesting person taking delivery?" 
                            checked={isRequestingPersonTakingDelivery}
                            onChange={() => setIsRequestingPersonTakingDelivery(!isRequestingPersonTakingDelivery)}
                        />
                        <small className="text-muted">
                            Only answer yes if you will take delivery of the security keys.
                        </small>
                    </Col>
                </Row>

                {/* Conditional receiver details input fields */}
                {!isRequestingPersonTakingDelivery && (
                    <Row className="mb-2">
                        <Row>
                            <Col xs={12} sm={6}>
                                <label htmlFor="receiverFirstName" className="form-label">Receiver First Name</label>
                                <Form.Control 
                                    type="text" 
                                    name="receiverFirstName"
                                    value={receiverDetails.receiverFirstName}
                                    onChange={handleChangeReceiverDetails}
                                />
                            </Col>
                            <Col xs={12} sm={6}>
                            <label class="mt-3" htmlFor="receiverLastName" className="form-label">Receiver Last Name</label>
                            <Form.Control 
                                type="text" 
                                name="receiverLastName"
                                value={receiverDetails.receiverLastName}
                                onChange={handleChangeReceiverDetails}
                            />
                        </Col>
                        </Row>
                        
                        
                        <Row>
                        <Col xs={12} sm={6}>
                                <label htmlFor="receiverMobile" className="form-label">Receiver Mobile</label>
                                <Form.Control 
                                    type="text" 
                                    name="receiverMobile"
                                    value={receiverDetails.receiverMobile}
                                    onChange={handleChangeReceiverDetails}
                                />
                            </Col>
                        <Col xs={12} sm={6}>
                            <label htmlFor="receiverEmail" className="form-label">Receiver Email</label>
                            <Form.Control 
                                type="email" 
                                name="receiverEmail"
                                value={receiverDetails.receiverEmail}
                                onChange={handleChangeReceiverDetails}
                            />
                        </Col>
                        </Row>
                        <hr class="mt-4"/>
                    </Row>


                )}





                    <Row className="mb-3">
                        <Col xs={12} sm={9}>
                            <label htmlFor="address" className="form-label">Address</label>
                            <AddressAutocomplete 
                            onSelectAddress={handleSelectAddress}
                            onCityChange={handleCityChange}
                            onStateChange={handleStateChange}
                            onPostcodeChange={handlePostcodeChange} />
                        </Col>
                        <Col xs={12} sm={3}>
                            <label htmlFor="unitNumber" className="form-label">Unit Number</label>
                            <input
                            type="text"
                            className={`form-control ${shouldMarkError('unitNumber') ? 'is-invalid' : ''}`}
                            id="unitNumber"
                            name="unitNumber"
                            value={formData.unitNumber}
                            autoComplete='address-line2'
                            onChange={handleChange}
                            />
                        </Col>
                    </Row>
                    <Row className='mb-5'>
                        <Col xs={12} sm={6}>
                        <label htmlFor="city" className="form-label">City</label>
                            <input
                            type="text"
                            className={`form-control ${shouldMarkError('city') ? 'is-invalid' : ''}`}
                            id="city"
                            name="city"
                            value={formData.city}
                            autoComplete="address-level2"
                            onChange={handleChange}
                            />
                        </Col>

                        <Col xs={12} sm={3}>
                        <label htmlFor="postcode" className="form-label">Postcode</label>
                            <input
                            type="text"
                            className={`form-control ${shouldMarkError('postcode') ? 'is-invalid' : ''}`}
                            id="postcode"
                            name="postcode"
                            value={formData.postcode}
                            autoComplete="postal-code"
                            onChange={handleChange}
                            />
                        </Col>

                        <Col xs={12} sm={3}>
                        <label htmlFor="state" className="form-label">State</label>
                        <input
                            type="text"
                            className={`form-control ${shouldMarkError('state') ? 'is-invalid' : ''}`}
                            id="state"
                            name="state"
                            value={formData.state}
                            autoComplete="address-level1"
                            onChange={handleChange}
                        />
                        </Col>
                    </Row>
                </form>

                <Row className='my-4'>
                    <Col xs={12} sm={8} className='px-1'>
                        <Button onClick={ handleContinue } variant='dark' size='lg' style={{ padding: '5px 30px', marginTop: '2em'}}>Complete</Button>
                    </Col>
                </Row>
            </Container>

        </div>
        )
}

export default DeliveryForm;