// VerificationProvider.js
import React, { useState } from 'react';
import VerificationContext from '../context/VerificationContext';

const VerificationProvider = ({ children }) => {
  const [isVerified, setIsVerified] = useState(false);

  return (
    <VerificationContext.Provider value={{ isVerified, setIsVerified }}>
      {children}
    </VerificationContext.Provider>
  );
};

export default VerificationProvider;