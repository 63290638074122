import React, { useContext } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import VerificationContext from '../context/VerificationContext';
import Sidebar from '../components/Sidebar';
import DeliveryForm from '../components/Form_Three';
import { Container } from 'react-bootstrap';

const CompletionPage = () => {
    const { isVerified } = useContext(VerificationContext);

    return (
        <>
        <Container className='mt-5'>
            <Row style={{ height: '100vh' }}>
                <Col xs={12} md={12}>
                    <Row className='mt-5'>
                        <Container>
                            <img src="/logomark.svg" style={{height:'100px', width:'100px'}} />
                            <h5>Bright-Duggan Key Device Request Form</h5>
                        </Container>
                        <h1>All done! We'll take it from here</h1>
                        <p>Your application will be verified and you will receive an email with any questions or the payment process</p>
                        <small>Thank you for using the Key Guardian service</small>
                    </Row>
                </Col>
            </Row>
        </Container>
        </>
    )
}

export default CompletionPage;