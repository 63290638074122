import React, { useEffect } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';

const DeviceSelector = ({ product, updateProduct, removeProduct }) => {
    useEffect(() => {
        updateProduct(product.id, product);
    }, [product, updateProduct]);

    const handleKeyTypeChange = (e) => {
        updateProduct(product.id, { ...product, keyType: e.target.value });
    };

    const handleQuantityChange = (e) => {
        updateProduct(product.id, { ...product, quantity: parseInt(e.target.value, 10) });
    };

    const handleTextInputChange = (e) => {
        updateProduct(product.id, { ...product, textInput: e.target.value });
    };

    return (
        <div>
            <Row className='my-2'>
                <Col xs={4} className='px-1'>
                    <Form.Select aria-label="Key type" onChange={handleKeyTypeChange} value={product.keyType}>
                        <option value="">Select</option>
                        <option value="Security Key">Security Key</option>
                        <option value="Electronic Keyfob">Electronic Keyfob</option>
                        <option value="Electronic Security Card">Electronic Security Card</option>
                        <option value="Remote Control">Remote Control</option>
                    </Form.Select>
                </Col>
                <Col xs={2} className='px-1'>
                    <Form.Select aria-label="Quantity" onChange={handleQuantityChange} value={product.quantity}>
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(num => (
                            <option key={num} value={num}>{num}</option>
                        ))}
                    </Form.Select>
                </Col>
                <Col xs={5} className='px-1'>
                    <Form.Control
                        type="text"
                        placeholder="Which door, level or shutter?"
                        onChange={handleTextInputChange}
                        value={product.textInput}
                    />
                </Col>
                <Col xs={1} className='px-1'>
                    <Button onClick={() => removeProduct(product.id)} className="ms-auto" variant="link" style={{ textDecoration: 'none' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M12 2.25C6.624 2.25 2.25 6.624 2.25 12C2.25 17.376 6.624 21.75 12 21.75C17.376 21.75 21.75 17.376 21.75 12C21.75 6.624 17.376 2.25 12 2.25ZM12 3.75C16.5645 3.75 20.25 7.4355 20.25 12C20.25 16.5645 16.5645 20.25 12 20.25C7.4355 20.25 3.75 16.5645 3.75 12C3.75 7.4355 7.4355 3.75 12 3.75ZM7.5 11.25V12.75H16.5V11.25H7.5Z" fill="#6D6D6D"/>
                        </svg>
                    </Button>
                </Col>
            </Row>
        </div>
    )
}

export default DeviceSelector;
