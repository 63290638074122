import React, { useState } from 'react';
import { useProgressContext } from '../context/ProgressContext';

const FormProgress = () => {
    // State to track the current step (1-indexed for ease of understanding)
    // const [currentStep, setCurrentStep] = useState(1);
    const steps = ['Details', 'Key Selection', 'Delivery'];
    const { progress } = useProgressContext();

    return (
        <div style={styles.container}>
          {steps.map((step, index) => (
            <div key={step} style={styles.stepContainer}>
              <div
                style={{
                  ...styles.step,
                  backgroundColor: index < progress ? '#007bff' : 'transparent',
                  border: `2px solid ${index < progress ? '#007bff' : 'black'}`,
                }}
              />
              <p style={styles.stepLabel}>{step}</p>
              {/* {index < steps.length - 1 && <div style={styles.dashedLine} />} */}
            </div>
          ))}
        </div>
      );
}

const styles = {
    container: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-around',
      width: '100%'
    },
    stepContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      position: 'relative',
    },
    step: {
      width: '40px',
      height: '40px',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    dashedLine: {
      position: 'absolute',
      top: '15px',
      left: '20px',
      width: '100px',
      borderBottom: '2px dashed black',
      zIndex: -1,
    },
    stepLabel: {
      marginTop: '5px',
      textAlign: 'center',
      fontSize: '14px',
    }
  };
  
export default FormProgress;
  