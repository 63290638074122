import React, { useState, useContext, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/Form.css'
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import VerificationContext from '../context/VerificationContext';
import strataApi from '../api/strataApi'
import { CidButton } from '@connectid-tools/idp-selector-react'
import axios from './../api/axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext'; // Adjust the path as needed
import { useProgressContext } from '../context/ProgressContext';
import { VerifiedWith } from './VerifiedWith';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import CidTooltip from './CidTooltip';

const KeyGuardianForm = ({ onScrollToSteps }) => {
  const { authData } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation(); // Location state
  const [touched, setTouched] = useState({});
  const { isVerified } = useContext(VerificationContext);
  const [error, setError] = useState({}); 
  const [autoFillEnabled, setAutoFillEnabled] = useState(false);
  const errorMessage = location.state?.errorMessage || null;
  let handleContinueCalled = false;


  useEffect(() => {
    if (authData && authData.claims) {
        const { claims } = authData;
        setFormData(prevData => ({
            ...prevData,
            firstName: claims.given_name || '',
            lastName: claims.family_name || '',
            email: claims.email || '',
            mobile: claims.phone_number || '',
            // Add other fields if needed
        }));
    }
  }, [authData]);

  const [formData, setFormData] = useState({
    strataPlanNumber: '',
    unitNumber: '',
    address: '',
    postcode: '',
    city: '',
    state: '',
    firstName: '',
    lastName: '',
    email: '',
    mobile: '',
    relationship: 'Owner',
    nickName: '' // Add nickName to the initial state
  });

  const isValid = (field) => {
    // Replace the following with your validation logic
    if(!formData[field]){
      return false;
    }

    if(formData[field].length > 2){
      return true;
    }

    if(field == 'nickName'){
      return true
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevData => ({ ...prevData, [name]: value }));
    if (['address', 'postcode', 'city', 'state'].includes(name)) {
      setAutoFillEnabled(false);
    }
  };

  useEffect(() => {
    if(formData.strataPlanNumber.length > 2){
      strataApi.get('/', {
        params: {
          plan: formData.strataPlanNumber
        }
      })
      .then(function (response) {
        let data = response.data.response.data;
        console.log(data)

        let stateData = {
          strataPlanNumber: formData.strataPlanNumber,
          address: data.address,
          postcode: data.postcode,
          city: data.suburb,
          state: data.state
        }

        setFormData(stateData)
        
      })
      .catch(function (error) {
        console.log(error);
      });
    }
    }, [formData.strataPlanNumber]);

  // Handles when element is focused
  const handleBlur = (event) => {
    const { name } = event.target;
    console.log(name);
    setTouched({ ...touched, [name]: true });
  };

  const shouldMarkError = (field) => {

    const hasError = error[field];
    return hasError ? 'is-invalid' : '';
    return (handleContinueCalled) && !isValid(field);
  };

  const selectBank = async (authorisationServerId) => {
    const essentialClaims = ['txn', 'given_name', 'family_name', 'email', 'phone_number' ];
    
    console.log(JSON.stringify({authorisationServerId, essentialClaims}))
    
    const response = await axios.post('/select-bank', { authorisationServerId, essentialClaims }, { withCredentials: true })
    .then((response) => {
      if (response.status !== 200) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const result = response.data;

      const authURL = result.authURL;
      window.location.href=authURL
    }).catch((error) => {
      navigate('/', { state: { errorMessage: `Oops! Something went wrong. Try again later` } })
      console.log('Selecting bank error: ' + error);
    });
  
  };

  const handleContinue = async () => {
    handleContinueCalled = true; // Set handleContinueCalled to true

    if (formData.unitNumber === '') {
      error.unitNumber = 'unitNumber';
    }
    setError(error);

    // 1. Upload to details state to the backend
    const formBody = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      mobile: formData.mobile,
      nickName: formData.nickName, 
      relation: formData.relationship,
      strataPlanNumber: formData.strataPlanNumber,
      address: formData.address,
      postcode: formData.postcode,
      city: formData.city,
      state: formData.state,
      unitNumber: formData.unitNumber,
    }
    if(errorMessage) {
      navigate(0, { state: { } })
    }
    if(!formData.unitNumber){
      shouldMarkError('unitNumber');
      return;
    } else if (!formData.address) {
      shouldMarkError('address');
      return;
    }
    try {
      const response = await axios.post('/personal-details', formBody, { withCredentials: true });
      if (response.status === 200) {
        console.log('Response:', response.data);
  
        // Store the userId for use in the next form
        const user = response.data.user  
        const building = response.data.building; // Store buildingId for use in the next form
        console.log('User ID:', user);
  
        localStorage.setItem('building', building); // Store buildingId in localStorage for use in the next form
        localStorage.setItem('user', user); // Store userId in localStorage for use in the next form
        navigate('/key-selection', { state: { user, building } }); // Pass userId as state to the next form
      } else {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    } catch (error) {
      console.log('Personal Details Submission error:', error);
    }
    }

    const strataPlanNumberTooltip = (
      <Tooltip id="strataPlanNumberTooltip">
        Enter strata plan number to auto fill building details. Check your levies or tenancy agreement to find this number if you don't know it.
      </Tooltip>
    );

    const scrollToSteps = () => {
      const sidebarElement = document.getElementById("sidebar");
      const yOffset = -20; // Adjust this value based on your header size or other offsets
      const y = sidebarElement.getBoundingClientRect().top + window.pageYOffset + yOffset;
  
      window.scrollTo({ top: y, behavior: 'smooth' });
  };
  

  return (
    <div className="main-form container">
      <Container>
        {/* <form className='form'> */}
          {/* <a href="#" onClick={(e) => { e.preventDefault(); onScrollToSteps(); }} className="scroll-to-steps mb-3">How to complete this form</a>           */}
          <h2>Personal Details</h2>
          {/* <h6>We manage your identity and keys quickly and safely</h6> */}
          {!isVerified &&
            <div>
              <p style={{ fontSize: '0.9em', marginTop: '1em', marginBottom: '0'}}>Seamless, secure identity verification with ConnectID.</p>
              <p style={{ fontSize: '0.9em'}}>ConnectID does not see or store your personal data.</p>
            </div>
          }
          <div className='my-2'>
            {!isVerified && <CidButton 
              label="Verify"
              certificationStatus="Active" 
              enableManualVerification={true}
              participantsUri={process.env.REACT_APP_PARTICIPANTS_URI}
              rpDataSharingDescription='Your details will be shared for the purpose of verifying your identity with Key Guardian'
              claims={['given_name', 'family_name', 'phone_number', 'email']}
              onProceed={(authServerID) => { selectBank(authServerID)}}
              rpLogoUrl='/KeyGuardainIcon500.png'
            />}

            <br />

            { errorMessage && <span style={{ color: 'red' }}>{errorMessage}</span> }

            { !isVerified && <CidTooltip /> }

            {isVerified && <VerifiedWith padding="5px 10px" />}

            {!isVerified &&
              <div>
                <hr />
                <p style={{ fontSize: '0.9em' }}>For alternate options email us at support@keyguardian.com.au and we can help</p>
              </div> }
          </div>
          
          {isVerified && <div>

          <Row className='mt-5 mb-3'>
            <Col xs={12} sm={5}>
              {/* First Name */}
              <label htmlFor="firstName" className="form-label">First Name</label>
                <input
                  type="text"
                  className={`form-control ${shouldMarkError('firstName') ? 'is-invalid' : ''}`}
                  id="firstname"
                  name="lastName"
                  value={formData.firstName}
                  readOnly={true}
                  disabled={true}
                />
            </Col>

            <Col xs={12} sm={5}>
              {/* Last Name */}
              <label htmlFor="lastName" className="form-label">Last Name</label>
                <input
                  type="text"
                  className={`form-control ${shouldMarkError('lastName') ? 'is-invalid' : ''}`}
                  id="lastName"
                  name="lastName"
                  value={formData.lastName}
                  readOnly={true}
                  disabled={true}
                />
            </Col>
          </Row>

          <Row>
            <Col xs={12} sm={6}>
              {/* Email */}
              <label htmlFor="email" className="form-label">Email</label>
              <input
                type="email"
                className={`form-control ${shouldMarkError('email') ? 'is-invalid' : ''}`}
                id="email"
                name="email"
                value={formData.email}
                readOnly={true}
                disabled={true}
              />
            </Col>
            <Col xs={12} sm={4}>
              {/* Last Name */}
              <label htmlFor="mobile" className="form-label">Mobile</label>
              <input
                type="text"
                className={`form-control ${shouldMarkError('mobile') ? 'is-invalid' : ''}`}
                id="mobile"
                name="mobile"
                value={formData.mobile}
                readOnly={true}
                disabled={true}
              />
            </Col>
          </Row>

          <hr />

          <Row className="mb-3">
            <Col xs={8} sm={6}>
              <label htmlFor="nickName" className="form-label">Preferred Name</label>
              <input
                type="text"
                className={`form-control ${shouldMarkError('nickName') ? 'is-invalid' : ''}`}
                id="nickName"
                name="nickName"
                placeholder='eg. Bill'
                value={ formData.nickName }
                onChange={ handleChange }
                onBlur={ handleBlur }
              />
            </Col>
            <Col xs={4} sm={4}>
              <label htmlFor="relationship" className="form-label">Relationship</label>
              <select className="form-select" id="relationship" name="relationship" 
              value={ formData.relationship } 
              onChange={ handleChange }>
                <option value="Owner">Owner</option>
                <option value="Proxy">Proxy</option>
                <option value="Agent">Agent</option>
              </select>
            </Col>
          </Row>

          <br />
          <br />
          <h2>Strata Verification</h2>

          <Row className="mb-3">
          <Col xs={8} sm={4}>
            <label htmlFor="strataPlanNumber" className="form-label">
              Strata Plan Number
              <OverlayTrigger
                placement="right"
                overlay={strataPlanNumberTooltip}
              >
                <i className="fas fa-question-circle" style={{ marginLeft: '5px', cursor: 'pointer' }}></i>
              </OverlayTrigger>
            </label>
            <input
              type="text"
              className={`form-control ${shouldMarkError('strataPlanNumber') ? 'is-invalid' : ''}`}
              id="strataPlanNumber"
              name="strataPlanNumber"
              placeholder='100'
              value={ formData.strataPlanNumber }
              onChange={ handleChange }
              onBlur={ handleBlur }
            />
          </Col>
        </Row>
          <Row className="mb-3">
              
              <Col xs={12} sm={4}>
                <label htmlFor="unitNumber" className="form-label">Unit Number</label>
                <input
                  type="text"
                  className={`form-control ${shouldMarkError('unitNumber') ? 'is-invalid' : ''}`}
                  id="unitNumber"
                  name="unitNumber"
                  value={formData.unitNumber}
                  onChange={ handleChange }
                  onBlur={ handleBlur }
                />
              </Col>
              <Col xs={12} sm={8}>
                <label htmlFor="address" className="form-label">Address</label>
                <input
                  type="text"
                  className={`form-control ${shouldMarkError('address') ? 'is-invalid' : ''}`}
                  id="address"
                  name="address"
                  value={formData.address}
                  onChange={ handleChange }
                  onBlur={ handleBlur }
                  // readOnly={true}
                  // disabled="disabled"
                />
              </Col>
          </Row>
          <Row className='mb-5'>
            <Col xs={12} sm={6}>
              <label htmlFor="city" className="form-label">City</label>
                <input
                  type="text"
                  className={`form-control ${shouldMarkError('city') ? 'is-invalid' : ''}`}
                  id="city"
                  name="city"
                  value={formData.city}
                  onChange={ handleChange }
                  onBlur={ handleBlur }
                  // readOnly={true}
                  // disabled="disabled"
                />
            </Col>

            <Col xs={12} sm={3}>
              <label htmlFor="postcode" className="form-label">Postcode</label>
                <input
                  type="text"
                  className={`form-control ${shouldMarkError('postcode') ? 'is-invalid' : ''}`}
                  id="postcode"
                  name="postcode"
                  value={formData.postcode}
                  onChange={ handleChange }
                  onBlur={ handleBlur }
                  // readOnly={true}
                  // disabled="disabled"
                />
            </Col>

            <Col xs={12} sm={3}>
              <label htmlFor="state" className="form-label">State</label>
              <input
                type="text"
                className={`form-control ${shouldMarkError('state') ? 'is-invalid' : ''}`}
                id="state"
                name="state"
                value={formData.state}
                onChange={ handleChange }
                onBlur={ handleBlur }
                // readOnly={true}
                // disabled="disabled"
              />
            </Col>
            </Row>
          
          
          <Row className='mb-4'>
              <Col s={8}>
                <Button onClick={ handleContinue }  variant="outline-dark"  size='lg' style={{ padding: '5px 30px', marginTop: '2em'}}>Continue</Button>
              </Col>
          </Row>

          </div>}

        {/* </form> */}
        <br />
        <br />
        <br />
        <br />
        <br />

      </Container>
    </div>
  );
};

export default KeyGuardianForm;
