import './App.css';
import { ProgressProvider } from './context/ProgressContext';
import FrontPage from './screens/Frontpage';
import SecondPage from './screens/Secondpage';
import DeliveryPage from './screens/DeliveryPage';
import CompletionPage from './screens/CompletionPage'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CallbackPage from './screens/Callback';
import { AuthProvider } from './context/AuthContext'; // Adjust the path as needed

function App() {
  return (
    <div className="App">
      <AuthProvider>
      <Router>
        <Routes>
          <Route path="/cb" element={
            <CallbackPage />
          } />
          <Route path="/" element={
            <ProgressProvider>
              <FrontPage />
            </ProgressProvider>
          } />
          <Route path='/key-selection' element={
            <ProgressProvider>
              <SecondPage />
            </ProgressProvider>
          } />
          <Route path='/delivery' element={
            <ProgressProvider>
              <DeliveryPage />
            </ProgressProvider>
          } />
          <Route path='/complete' element={
            <ProgressProvider>
              <CompletionPage />
            </ProgressProvider>
          } />
        </Routes>
      </Router>
      </AuthProvider>
    </div>
  );
}

export default App;
