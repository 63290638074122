import React, { useState,useEffect, useContext } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import VerificationContext from '../context/VerificationContext';
// import { useProgressContext } from '../context/ProgressContext';
import KeyGuardianForm from '../components/Form_One';
import KeySelectionForm from '../components/Form_Two';
import Sidebar from '../components/Sidebar';
import { Container } from 'react-bootstrap';
import FormProgress from '../components/FormProgress';

const FrontPage = () => {
    const { isVerified } = useContext(VerificationContext);

    const [isSidebarExpanded, setIsSidebarExpanded] = useState(false);

    useEffect(() => {
        if (isSidebarExpanded) {
            const sidebarElement = document.getElementById("sidebar");
            if (sidebarElement) {
                sidebarElement.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [isSidebarExpanded]);

    const toggleSidebarExpansion = () => {
        setIsSidebarExpanded(!isSidebarExpanded);
    };
    return (
        <>
            <Row style={{ height: '100vh' }}>
                <Col xs={12} md={8}>
                    <Row>
                        <Col xs={12} md={2}></Col>
                        <Col xs={12} md={8}>
                            <Container>
                                <Row>
                                    <Col>
                                        <img src="/logomark.svg" style={{height:'100px', width:'100px'}} />
                                    </Col>
                                    {/* <Col>
                                        <img src="/BDPG-logo-horizontal.pdf.svg" style={{height:'100px', width:'180px'}} />
                                    </Col> */}
                                </Row>
                                <h5>Bright-Duggan Key Device Request Form</h5>
                                <FormProgress />
                            </Container>
                            <KeyGuardianForm onScrollToSteps={toggleSidebarExpansion} />
                            </Col>
                        <Col xs={12} md={2}></Col>
                    </Row>
                </Col>
                <Col xs={12} md={4} style={{ backgroundColor: '#D1F7FF'}}>
                <Sidebar isExpanded={isSidebarExpanded} />                </Col>
            </Row>
        </>
    )
}

export default FrontPage;